export default function ($t) {
  return [
    {
      id: 'signup',
      title: $t('components.website.navigation.signup'),
      icon: 'mdi-account-plus',
      to: '/signup',
      order: 10,
      auth: false,
    },
    {
      id: 'signin',
      title: $t('components.website.navigation.signin'),
      icon: 'mdi-login',
      to: '/signin',
      order: 20,
      auth: false,
    },
    {
      divider: true,
      order: 30,
    },
    {
      id: 'admin',
      title: $t('components.website.navigation.admin'),
      icon: 'mdi-desktop-mac-dashboard',
      order: 100,
      auth: true,
      admin: true,
      actions: [
        {
          id: 'admin_options',
          title: $t('components.website.navigation.adminMenu'),
          component: {
            tag: 'AdminMenu',
            factory: () => ({
              component: import('@peynman/press-vue-admin/components/Navigation/AdminMenu.vue'),
            }),
          },
        },
      ],
    },
    {
      divider: true,
      order: 200,
      auth: true,
      admin: true,
    },
    {
      id: 'home',
      title: $t('components.website.navigation.home'),
      icon: 'mdi-home',
      to: '/',
      order: 1000,
    },
    {
      id: 'shop',
      title: $t('components.website.navigation.shop'),
      icon: 'mdi-shopping',
      to: '/shop',
      order: 1000,
    },
    {
      id: 'search',
      title: $t('components.website.navigation.search'),
      icon: 'mdi-magnify',
      to: '/search',
      order: 1000,
    },
    {
      divider: true,
      order: 1199,
    },
    {
      id: 'support',
      title: $t('components.website.navigation.support'),
      icon: 'mdi-phone-message',
      to: '/support',
      order: 1200,
    },
    // {
    //   id: 'about',
    //   title: $t('components.website.navigation.about'),
    //   icon: 'mdi-information',
    //   to: '/about',
    //   order: 1210,
    // },
    {
      id: 'branches',
      title: $t('components.website.navigation.branches'),
      icon: 'mdi-store',
      to: '/branches',
      order: 1210,
    },
    {
      id: 'contact',
      title: $t('components.website.navigation.contact'),
      icon: 'mdi-card-account-phone',
      to: '/contact',
      order: 1300,
    },
    {
      divider: true,
      order: 1400,
    },
    {
      id: 'exit',
      title: $t('components.website.navigation.exit'),
      icon: 'mdi-exit-run',
      click () {
        this.$store.dispatch('logout')
        if (this.$router.currentRoute.path !== '/') {
          setTimeout(() => {
            this.$router.push('/')
          }, 100)
        }
      },
      order: 10000,
      auth: true,
    },
  ]
}

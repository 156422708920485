<template>
  <v-menu
    :light="theme.website.userCart.menu.light"
    :dark="theme.website.userCart.menu.dark"
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{on}">
      <v-badge
        overlap
        left
        :value="cartItemsCount > 0"
        :content="itemsCountString"
        :color="theme.website.userCart.menu.badge"
      >
        <v-btn
          v-bind="cartItemsCount > 0 ? theme.website.userCart.menu.buttonActive : theme.website.userCart.menu.buttonInactive"
          icon
          small
          :loading="isCartLoading"
          v-on="on"
        ><v-icon>mdi-cart-outline</v-icon></v-btn>
      </v-badge>
    </template>
    <v-card
      :color="theme.website.userCart.menu.color"
      :light="theme.website.userCart.menu.light"
      :dark="theme.website.userCart.menu.dark"
    >
      <user-cart-list :cart="cart" max-height="330px" />
      <v-divider />
      <v-card-actions v-if="cartItemsCount > 0" class="pa-0 d-flex flex-column flex-grow-1">
        <v-list color="transparent" width="100%">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="d-flex flex-row justify-space-around align-center">
                <span>{{ $t('components.website.userCart.cartAmount') }}</span>
                <span>{{ cartAmountString }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="cartPromotions && cartPromotions.length > 0">
            <v-list-item-content>
              <v-list-item-title class="d-flex flex-row justify-space-around align-center">
                <span>{{ $t('components.website.userCart.promotionsOff') }}</span>
                <span class="green--text">{{ cartPromotionsAmountString }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-btn v-bind="theme.website.userCart.menu.buttonSubmit" to="/me/cart">
                {{ $t('components.website.userCart.showDetails') }}
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
  import UserPurchasingCart from '@peynman/press-vue-core/mixins/UserPurchasingCart'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import UserCartList from './UserCartList.vue'

  export default {
    name: 'UserCartMenu',
    components: {
      UserCartList,
    },
    mixins: [
      UserPurchasingCart,
      Themeable,
    ],
    computed: {
      itemsCountString () {
        return this.$n(this.cartItemsCount, 'decimal')
      },
      isCartLoading () {
        return this.$store.getters['cart/isLoading']
      },
    },
  }
</script>

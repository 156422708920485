<template>
  <v-footer
    :padless="theme.website.footer.padless"
    :dark="theme.website.footer.dark"
    :light="theme.website.footer.dark"
  >
    <v-card
      tile
      flat
      light
      class="fill-width"
      color="#efefef"
    >
      <v-card-title class="d-flex flex-row justify-center align-center white" style="min-height: 60px;">
        <span>{{ $t('components.website.footer.title') }}</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-col cols="12" sm="12" md="6" offset-md="3">
          <v-row>
            <v-col cols="5" sm="5" xs="5">
              <span class="text-h6">
                <span>{{ $t('components.website.footer.customers') }}</span>
                <div style="width: 40px; min-height: 3px; background-color: black;" class="my-2" />
                <alt-footer-links :links="extraLinks" />
              </span>
            </v-col>
            <v-col cols="2" />
            <v-col cols="5" sm="5" xs="5">
              <span class="text-h6">
                <span>{{ $t('components.website.footer.info') }}</span>
                <div style="width: 40px; min-height: 3px; background-color: black;" class="my-2" />
                <alt-footer-links :links="links" />
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-text>
        <v-row align="center" justify="center" class="pb-2 pt-4">
          <span class="caption">{{ $t('components.website.footer.social') }}</span>
        </v-row>
        <v-row align="center" justify="center" class="pb-4">
          <social-links class="me-2" />
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import SocialLinks from './SocialLinks.vue'
  import AltFooterLinks from './AltFooterLinks.vue'
  import Links from './links'
  import ExtraLinks from './extraLinks'

  export default {
    name: 'AltFooter',
    components: {
      SocialLinks,
      AltFooterLinks,
    },
    mixins: [Themeable],
    computed: {
      extraLinks () {
        return []
          .concat(ExtraLinks(this))
          .concat(this.theme.footerExtraLinks ?? [])
          .sort((a, b) => a.order - b.order)
      },
      links () {
        return []
          .concat(Links(this))
          .concat(this.theme.footerMenuLinks ?? [])
          .sort((a, b) => a.order - b.order)
      },
    },

  }
</script>

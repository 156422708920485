<template>
  <div class="d-flex flex-column">
    <v-card-title :class="headerClass">{{ $t('components.website.signup.title') }}</v-card-title>
    <v-divider />
    <v-card-text :class="bodyClass">
      <validations-alert :type="formAlertType" :errors="formAlertErrorList" :message="formAlertMessage" />
      <!-- Send verify code -->
      <v-form v-if="step === 0" ref="sendVerifyCodeForm" v-model="sendVerifyCodeFormValid">
        <span class="text-title">{{ $t('components.website.signup.verifyMessage') }}</span>
        <v-text-field
          v-model="phone"
          :label="$t('components.website.signup.phone')"
          :hint="$t('components.website.signup.phoneHint')"
          :rules="[getRequiredRule(), getPhoneNumberRule()]"
          :error-messages="getPropertyErrorMessages('phone')"
          v-bind="theme.website.input"
        />
        <captcha-input
          v-model="captcha"
          :rules="[getRequiredRule()]"
          :error-messages="getPropertyErrorMessages('captcha')"
          :input-theme="theme.website.input"
          @submit="onSubmitPhoneNumber"
        />
        <!-- <v-dialog v-model="termsDialog" max-width="720">
          <template v-slot:activator="{on}">
            <v-btn class="mt-3" depressed color="secondary" v-on="on">
              {{ $t('components.website.signup.viewTerms') }}
            </v-btn>
          </template>
          <terms-card @accept="() => {termsDialog = false; acceptTerms = true;}" />
        </v-dialog> -->
        <!-- <v-checkbox
          v-model="acceptTerms"
          :label="$t('components.website.signup.acceptTerms')"
          :rules="[getRequiredRule()]"
          :error-messages="getPropertyErrorMessages('acceptTerms')"
          v-bind="theme.website.input"
        /> -->
      </v-form>
      <!-- Check verify code -->
      <v-form v-if="step === 1" ref="resolveVerifyCodeForm" v-model="checkVerifyCodeFormValid">
        <v-text-field
          v-model="phone"
          :label="$t('components.website.signup.phone')"
          :hint="$t('components.website.signup.phoneHint')"
          disabled
          v-bind="theme.website.input"
        />
        <v-text-field
          v-model="code"
          :label="$t('components.website.signup.code')"
          :hint="$t('components.website.signup.codeHint')"
          :rules="[getRequiredRule()]"
          :error-messages="getPropertyErrorMessages('code')"
          v-bind="theme.website.input"
        />
      </v-form>
      <!-- New registration -->
      <v-form v-if="step === 2" ref="registerForm" v-model="registerFormValid">
        <v-text-field
          v-model="phone"
          :label="$t('components.website.signup.phone')"
          :hint="$t('components.website.signup.phoneHint')"
          disabled
          v-bind="theme.website.input"
        />
        <v-text-field
          v-model="firstname"
          :label="$t('components.website.signup.firstname')"
          :hint="$t('components.website.signup.firstnameHint')"
          :rules="[
            getRequiredRule(),
          ]"
          v-bind="theme.website.input"
        />
        <v-text-field
          v-model="lastname"
          :label="$t('components.website.signup.lastname')"
          :hint="$t('components.website.signup.lastnameHint')"
          :rules="[
            getRequiredRule(),
          ]"
          v-bind="theme.website.input"
        />
        <slot name="register" />
      </v-form>

      <!-- Reset password -->
      <v-form v-if="step === 3" ref="resetForm" v-model="resetFormValid">
        <v-text-field
          v-model="phone"
          :label="$t('components.website.signup.phone')"
          :hint="$t('components.website.signup.phoneHint')"
          disabled
          v-bind="theme.website.input"
        />
        <v-text-field
          v-model="password"
          :label="$t('components.website.signup.password')"
          :hint="$t('components.website.signup.passwordHint')"
          :rules="[getRequiredRule(), getMinLengthRule(6)]"
          type="password"
          v-bind="theme.website.input"
        />
        <v-text-field
          v-model="password_confirmation"
          :label="$t('components.website.signup.passwordConfirmation')"
          :rules="[getRequiredRule(), getMinLengthRule(6)]"
          type="password"
          v-bind="theme.website.input"
        />
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions :class="`d-flex flex-wrap flex-row align-center justify-start ${actionsClass}`">
      <v-btn
        v-if="step === 0"
        color="green"
        :dark="true"
        :loading="loading"
        @click="onSubmitPhoneNumber"
      >{{ $t('components.website.signup.verifySubmit') }}</v-btn>

      <v-btn
        v-if="step === 1"
        color="green"
        :dark="true"
        :loading="loading"
        @click="onCheckVerifyCode"
      >{{ $t('components.website.signup.verifyCheck') }}</v-btn>

      <v-btn
        v-if="step === 2"
        color="green"
        :dark="true"
        :loading="loading"
        @click="onRegister"
      >{{ $t('components.website.signup.register') }}</v-btn>

      <v-btn
        v-if="step === 3"
        color="green"
        :dark="true"
        :loading="loading"
        @click="onResetPassword"
      >{{ $t('components.website.signup.resetPassword') }}</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CaptchaInput from '@peynman/press-vue-admin/components/Inputs/CaptchaInput/CaptchaInput.vue'
  import ValidationsAlert from '@peynman/press-vue-admin/components/Inputs/ValidationsAlert/ValidationsAlert.vue'
  import FormValidations from '@peynman/press-vue-core/mixins/FormValidations'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import { toEnglishDigits } from '@peynman/press-vue-core/utils/helpers'
  import Constants from '../../constants'
  // import TermsCard from './TermsCard.vue'

  export default {
    name: 'SignupForm',
    components: {
      CaptchaInput,
      ValidationsAlert,
      // TermsCard,
    },
    mixins: [
      Themeable,
      FormValidations([
        'phone',
        'captcha',
        'acceptTerms',
        'code',
        'username',
        'password',
        'password_confirmation',
        'firstname',
        'lastname',
      ])],
    props: {
      bodyClass: String,
      headerClass: String,
      actionsClass: String,
    },
    data: () => ({
      msgId: null,
      sendVerifyCodeFormValid: true,
      checkVerifyCodeFormValid: true,
      registerFormValid: true,
      resetFormValid: true,
      loading: false,
      step: 0,
      termsDialog: false,
    }),
    computed: {
      ...mapGetters('captcha', [
        'captchaLoading',
        'captchaKey',
      ]),
    },
    methods: {
      onSubmitPhoneNumber () {
        this.$refs.sendVerifyCodeForm.validate()

        if (this.sendVerifyCodeFormValid) {
          this.loading = true
          this.$store.dispatch('auth/sendPhoneNumberVerifyCode', {
            phone: toEnglishDigits(this.phone),
            captcha: toEnglishDigits(this.captcha),
            acceptTerms: true, // this.acceptTerms,
            key: this.captchaKey,
          })
            .then(json => {
              this.step = 1
              this.updateFormSuccessMessage(json.message)
            })
            .catch(error => {
              this.updateFormValidationErrors(error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      onCheckVerifyCode () {
        this.$refs.resolveVerifyCodeForm.validate()

        if (this.checkVerifyCodeFormValid) {
          this.loading = true
          this.$store.dispatch('auth/verifyPhoneNumber', {
            phone: toEnglishDigits(this.phone),
            code: toEnglishDigits(this.code),
          })
            .then(json => {
              if (json.reset) {
                this.step = 3
              } else {
                this.step = 2
              }
              this.msgId = json.msgId
              this.updateFormSuccessMessage(json.message)
            })
            .catch(error => {
              this.updateFormValidationErrors(error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      onRegister () {
        this.$refs.registerForm.validate()

        if (this.registerFormValid) {
          this.loading = true
          this.$store.dispatch('auth/registerWithPhoneNumber', {
            phone: toEnglishDigits(this.phone),
            msgId: this.msgId,
            username: toEnglishDigits(this.phone),
            password: this.phone.substring(0, 6),
            password_confirmation: this.phone.substring(0, 6),
            'customer-profile': {
              firstname: this.firstname,
              lastname: this.lastname,
            },
          })
            .then(json => {
              this.updateFormSuccessMessage(json.message)
              this.$store.dispatch('analytics/trackGoalConversion', {
                goalId: Constants.Matomo.Goals.signup_success,
              })
              setTimeout(() => { this.$emit('success') }, this.theme.website.signinRedirectDelay)
            })
            .catch(error => {
              this.updateFormValidationErrors(error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      onResetPassword () {
        this.$refs.resetForm.validate()

        if (this.resetFormValid) {
          this.loading = true
          this.$store.dispatch('auth/resetWithPhoneNumber', {
            phone: toEnglishDigits(this.phone),
            msgId: this.msgId,
            password: toEnglishDigits(this.password),
            password_confirmation: toEnglishDigits(this.password_confirmation),
          })
            .then(json => {
              this.updateFormSuccessMessage(json.message)
              setTimeout(() => { this.$emit('success') }, this.theme.signinDelay)
            })
            .catch(error => {
              this.updateFormValidationErrors(error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
    },
  }
</script>

<template>
  <v-list dense color="transparent">
    <v-list-item
      v-for="(link, index) in links"
      :key="`quick-link-${index}`"
      text
      :small="!$vuetify.breakpoint.xs"
      :x-small="$vuetify.breakpoint.xs"
      :to="link.to"
      :href="link.href"
      :target="link.target"
    >
      <v-list-item-avatar v-if="link.icon">
        <v-icon small>
          {{ link.icon }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <span style="color: #8D9193;">
            {{ link.title }}
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'

  export default {
    name: 'AltFooterLinks',
    mixins: [Themeable],
    props: {
      links: Array,
    },
  }
</script>

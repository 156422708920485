export default $component => ([
  {
    icon: 'mdi-instagram',
    href: 'instagram://user?username=modena_men',
    title: $component.$t('components.website.footer.instagram'),
  },
  {
    icon: 'mdi-whatsapp',
    href: 'whatsapp://send?abid=989377883535',
    title: $component.$t('components.website.footer.whatsapp'),
  },
])

<template>
  <div class="d-flex flex-row justify-center align-center">
    <div
      v-for="(link, index) in links"
      :key="`appbar-link-${index}`"
    >
      <hover-menu-item mode-btn :link="link" />
    </div>
    <v-progress-circular v-if="isProductCategoriesLoading" class="mx-3" indeterminate size="16" width="2" />
  </div>
</template>

<script>
  import Menu from './menu'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import { mapGetters } from 'vuex'
  import ProductCategories from '@peynman/press-vue-core/mixins/ProductCategories'
  import HoverMenuItem from '@peynman/press-vue-admin/components/HoverMenu/HoverMenuItem.vue'

  export default {
    name: 'ProductCategoriesMenu',
    components: {
      HoverMenuItem,
    },
    mixins: [
      Themeable,
      ProductCategories,
    ],
    data: vm => ({
      categories: [],
      menu: {},
    }),
    computed: {
      links () {
        return []
          .concat(Menu(m => this.$t(m)))
          .concat(this.categories)
          .concat(this.theme.appbarMenuLinks ?? [])
          .sort((a, b) => a.order - b.order)
      },
      ...mapGetters('repos', [
        'isProductCategoriesLoading',
      ]),
    },
    mounted () {
      this.getProductCategoriesHeirarchy(c => !!c.data.queryFrontEnd).then(categories => {
        this.categories = categories
      })
    },
  }
</script>

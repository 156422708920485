<template>
  <v-dialog v-model="dialog" max-width="766px" @input="$emit('dialog', dialog)">
    <template v-slot:activator="{on}">
      <slot name="activator" :on="on" />
    </template>
    <v-card
      :dark="theme.website.signing.dark"
      :light="theme.website.signing.light"
      :color="theme.website.signing.color"
    >
      <v-tabs
        v-model="currTab"
        :centered="theme.website.signing.tabs.centered"
        :dark="theme.website.signing.tabs.dark"
        :light="theme.website.signing.tabs.light"
        :color="theme.website.signing.tabs.color"
        :background-color="theme.website.signing.tabs.background"
      >
        <v-tab>
          <span>{{ $t('components.website.signin.title') }}</span>
        </v-tab>
        <v-tab>
          <span>{{ $t('components.website.signup.title') }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="currTab"
      >
        <v-tab-item>
          <signin-form
            :header-class="theme.website.signing.header"
            :body-class="theme.website.signing.color"
            :actions-class="theme.website.signing.footer"
            @success="onSigninSuccess"
          />
        </v-tab-item>
        <v-tab-item>
          <signup-form
            :header-class="theme.website.signing.header"
            :body-class="theme.website.signing.color"
            :actions-class="theme.website.signing.footer"
            @success="onSigninSuccess"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
  import SigninForm from './SigninForm.vue'
  import SignupForm from './SignupForm.vue'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'

  export default {
    name: 'SigningDialog',
    components: {
      SigninForm,
      SignupForm,
    },
    mixins: [Themeable],
    props: {
      tab: Number,
      value: Boolean,
    },
    data: vm => ({
      currTab: vm.tab,
      dialog: vm.value,
    }),
    watch: {
      value (v) {
        this.dialog = v
      },
    },
    methods: {
      onSigninSuccess () {
        this.dialog = false
      },
    },
  }
</script>

export default {
  Matomo: {
    Goals: {
      visited_checkout: 1,
      visited_bank: 2,
      success_purchase: 3,
      signup_success: 4,
      add_new_address: 5,
    },
  },
}

export default $component => ([
  {
    id: 'support',
    title: $component.$t('components.website.navigation.support'),
    to: '/support',
    order: 1200,
  },
  {
    id: 'branches',
    title: $component.$t('components.website.navigation.branches'),
    to: '/branches',
    order: 1300,
  },
  {
    id: 'contact',
    title: $component.$t('components.website.navigation.contact'),
    to: '/contact',
    order: 1400,
  },
])

<template>
  <v-app-bar
    :app="previewMode ? false : true"
    :hide-on-scroll="!$vuetify.breakpoint.xs"
    :color="appBarBackColor"
    :dark="theme.website.appbar.dark"
    :light="theme.website.appbar.light"
    :height="$dim(theme.website.appbar.height)"
    :style="rootStyles"
    elevation="0"
  >
    <template
      v-if="!$vuetify.breakpoint.xs && !theme.website.appbar.hideToolbar"
      v-slot:extension
    >
      <div class="d-flex flex-row justify-center align-center flex-grow-1">
        <v-toolbar
          flat
          color="transparent"
          :dark="theme.website.appbar.dark"
          :light="theme.website.appbar.light"
          class="d-flex flex-row justify-center align-center"
          style="direction: rtl;"
        >
          <v-btn
            class="me-2"
            icon
            small
            to="/search"
          ><v-icon>mdi-magnify</v-icon></v-btn>
          <appbar-extension />
        </v-toolbar>
        <user-cart-menu />
      </div>
    </template>
    <v-app-bar-nav-icon @click="$emit('toggle-drawer')" />
    <v-spacer />
    <signing-dialog
      v-if="!isLoggedIn"
      :value="showSigninDialog"
      :tab="0"
      @dialog="onDialog"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          class="mx-1"
          icon
          small
          v-on="on"
        ><v-icon>mdi-account</v-icon></v-btn>
      </template>
    </signing-dialog>
    <notifications-menu v-if="isLoggedIn" />
    <user-account-menu
      v-if="isLoggedIn && !$vuetify.breakpoint.xs"
      :color="theme.website.appbar.menu.color"
      :light="theme.website.appbar.menu.light"
      :dark="theme.website.appbar.menu.dark"
    >
      <template v-slot:activator="{ on }">
        <user-account-bar-icon
          :color="theme.website.appbar.account.color"
          :light="theme.website.appbar.account.light"
          :dark="theme.website.appbar.account.dark"
          @click="on.click"
        />
      </template>
    </user-account-menu>
    <user-cart-menu v-if="$vuetify.breakpoint.xs" />
    <!-- <social-links v-if="!$vuetify.breakpoint.xs || isLoggedIn" :small="$vuetify.breakpoint.xs" /> -->
    <div style="position: absolute; left: 50%;">
      <!--- LOGO ---->
      <v-app-bar-title class="ms-2" :style="`margin-left: -${$dim(theme.website.appbar.imageWidth)/2}px;`">
        <v-card color="transparent" elevation="0" to="/" tile>
          <v-img
            :src="theme.website.appbar.image"
            :max-width="$dim(theme.website.appbar.imageWidth)"
          />
        </v-card>
      </v-app-bar-title>
    </div>
  </v-app-bar>
</template>

<script>
  import NotificationsMenu from '@peynman/press-vue-admin/components/AppBar/NotificationsMenu.vue'
  import UserAccountMenu from '@peynman/press-vue-admin/components/UserAccount/UserAccountMenu.vue'
  import UserAccountBarIcon from './UserAccountBarIcon.vue'
  import UserProfile from '@peynman/press-vue-core/mixins/UserProfile'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import UserCartMenu from '../UserCart/UserCartMenu.vue'
  import SigningDialog from '../Auth/SigningDialog.vue'
  import AppbarExtension from './AppbarExtension.vue'
  // import SocialLinks from '../Footer/SocialLinks.vue'

  export default {
    name: 'WebsiteAppbar',
    components: {
      NotificationsMenu,
      UserAccountMenu,
      UserAccountBarIcon,
      UserCartMenu,
      SigningDialog,
      AppbarExtension,
      // SocialLinks,
    },
    mixins: [UserProfile, Themeable],
    props: {
      previewMode: Boolean,
      changeColorOnScroll: Boolean,
    },
    data: vm => ({
      search: false,
      searchTerm: null,
      appBarBackColor: null,
    }),
    computed: {
      showSigninDialog () {
        return this.$store.getters.shouldOpenSigninDialog
      },
      rootStyles () {
        return (this.theme.website?.appbar?.styles ?? []).join(';')
      },
    },
    mounted () {
      if (this.changeColorOnScroll) {
        window.addEventListener('scroll', () => {
          this.changeColor()
        })
        window.addEventListener('touchmove', () => {
          this.changeColor()
        })
      }
      this.appBarBackColor = this.changeColorOnScroll ? this.theme.website.appbar.unscrolledColor : this.theme.website.appbar.color
    },
    methods: {
      onDialog (dialog) {
        if (!dialog) {
          this.$store.commit('closeSigninDialog')
        }
      },
      changeColor () {
        if (window.pageYOffset <= 100) {
          this.appBarBackColor = this.theme.website.appbar.unscrolledColor
        } else {
          this.appBarBackColor = this.theme.website.appbar.color
        }
      },
    },
  }
</script>

<template>
  <v-navigation-drawer
    v-model="internalValue"
    app
    temporary
    width="300px"
    :right="theme.website.navigation.right"
    :color="theme.website.navigation.color"
    :dark="theme.website.navigation.dark"
    :light="theme.website.navigation.light"
  >
    <v-list
      :color="theme.website.navigation.color"
      :dark="theme.website.navigation.dark"
      :light="theme.website.navigation.light"
    >
      <v-sheet
        class="d-flex align-center justify-center"
        :height="$dim(theme.website.navigation.header.height)"
      >
        <v-img
          :src="theme.website.navigation.header.image"
          :max-width="$dim(theme.website.navigation.header.imageWidth)"
        />
      </v-sheet>
      <user-account-detailed
        v-if="isLoggedIn"
        :color="theme.website.navigation.menu.color"
        :dark="theme.website.navigation.menu.dark"
        :light="theme.website.navigation.menu.light"
      />
      <v-divider />
      <navigation-item v-for="(link, index) in links" :key="`nav-${index}`" :link="link" />
      <v-divider />
      <v-list-item v-if="$vuetify.breakpoint.xs">
        <v-list-item-content>
          <v-btn text @click="internalValue = !internalValue">
            <v-icon class="mx-2">mdi-chevron-double-right</v-icon>
            {{ $t('components.website.navigation.return') }}
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import NavigationItem from '@peynman/press-vue-admin/components/Navigation/NavigationItem.vue'
  import UserAccountDetailed from '@peynman/press-vue-admin/components/UserAccount/UserAccountDetailed.vue'
  import UserProfile from '@peynman/press-vue-core/mixins/UserProfile'
  import MenuItems from './menu'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import ProductCategories from '@peynman/press-vue-core/mixins/ProductCategories'

  export default {
    name: 'WebsiteNavigation',
    components: {
      NavigationItem,
      UserAccountDetailed,
    },
    mixins: [
      UserProfile,
      Themeable,
      ProductCategories,
    ],
    props: {
      value: Boolean,
    },
    data: vm => ({
      internalValue: vm.value,
      categories: [],
    }),
    computed: {
      links () {
        return MenuItems(m => this.$t(m))
          .filter(m => (m.auth === undefined || (m.auth === false && !this.isLoggedIn) || (m.auth === true && this.isLoggedIn)))
          .filter(m => (m.admin === undefined || (m.admin === false && !this.isAdmin) || (m.admin === true && this.isAdmin)))
          .concat(this.categories)
          .concat(this.theme.navigationMenuLinks)
          .sort((a, b) => a.order - b.order)
      },
    },
    watch: {
      value (n) {
        this.internalValue = n
      },
      internalValue (n) {
        this.$emit('input', n)
      },
    },

    mounted () {
      this.getProductCategoriesHeirarchy(c => !!c.data?.queryFrontEnd).then(categories => {
        this.categories = categories
      })
    },
  }
</script>

<template>
  <div class="d-flex flex-row align-center justify-center" style="min-height: 40px;">
    <v-tooltip
      v-for="(link, index) in socialLinks"
      :key="`footer-link-${index}`"
      top
    >
      <template v-slot:activator="{on}">
        <v-btn
          icon
          :href="link.href"
          target="__blank"
          small
          v-on="on"
        >
          <v-icon :small="small">{{ link.icon }}</v-icon>
        </v-btn>
      </template>
      {{ link.title }}
    </v-tooltip>
  </div>
</template>

<script>
  import Socials from './socials'

  export default {
    name: 'SocialLinks',
    props: {
      small: Boolean,
    },
    computed: {
      socialLinks () {
        return Socials(this)
      },
    },
  }
</script>

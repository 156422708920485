<template>
  <div class="fill-height">
    <v-app-bar
      v-if="theme.website.featuredHeader.show"
      fixed
      dense
      style="z-index: 7; text-align: center;"
      :color="theme.website.featuredHeader.color"
    >
      <div class="d-flex flex-grow-1 align-center justify-center">
        {{ theme.website.featuredHeader.message }}
      </div>
    </v-app-bar>
    <website-navigation
      v-model="showNav"
    />
    <slot name="top" />
    <website-appbar
      v-if="!hideAppbar"
      :preview-mode="previewMode"
      :change-color-on-scroll="changeBarColorOnScroll"
      :dark="appbarDark"
      :light="appbarLight"
      @toggle-drawer="showNav = !showNav"
    />
    <v-main :class="themeMainClass">
      <slot name="toolbar" />
      <v-container :fluid="fluidContainer" :class="themeContainerClass">
        <slot />
      </v-container>
    </v-main>
    <slot name="footer" />
    <!-- <website-footer v-if="!hideFooter" /> -->
    <alt-footer v-if="!hideFooter" />
  </div>
</template>

<script>
  import WebsiteAppbar from '../../components/Appbar/WebsiteAppbar.vue'
  // import WebsiteFooter from '../../components/Footer/WebsiteFooter.vue'
  import WebsiteNavigation from '../../components/Navigation/WebsiteNavigation.vue'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import AltFooter from '../../components/Footer/AltFooter.vue'

  export default {
    name: 'TemplateWebsite',
    components: {
      WebsiteAppbar,
      WebsiteNavigation,
      // WebsiteFooter,
      AltFooter,
    },
    mixins: [Themeable],
    props: {
      mainClass: String,
      fluidContainer: Boolean,
      containerClass: String,
      hideFooter: Boolean,
      hideAppbar: Boolean,
      previewMode: Boolean,
      changeBarColorOnScroll: Boolean,
      appbarDark: Boolean,
      appbarLight: Boolean,
    },
    data: () => ({
      showNav: false,
    }),
    computed: {
      themeFluidContainer () {
        return this.fluidContainer || this.theme.website.body.fluid
      },
      themeMainClass () {
        return this.mainClass || this.theme.website.body.mainClass
      },
      themeContainerClass () {
        return this.containerClass || this.theme.website.body.containerClass
      },
    },
    mounted () {
      this.updateVuetifyThemeColors(this.theme.website.vuetify)
    },
  }
</script>

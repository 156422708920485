export default function ($t) {
  return [
    {
      id: 'shop',
      title: $t('components.website.appbar.shop'),
      to: '/shop',
      order: 1000,
      activeClass: 'secondary',
    },
    {
      id: 'support',
      title: $t('components.website.navigation.support'),
      to: '/support',
      order: 1100,
      activeClass: 'primary',
    },
    // {
    //   id: 'about',
    //   title: $t('components.website.navigation.about'),
    //   to: '/about',
    //   order: 1200,
    // },
    {
      id: 'contact',
      title: $t('components.website.navigation.contact'),
      to: '/contact',
      order: 1300,
      activeClass: 'primary',
    },
  ]
}

<template>
  <v-list
    dense
    class="pa-0"
    :color="color"
    :dark="dark"
    :light="light"
  >
    <v-list-item dense class="pa-0" width="100" style="direction: rtl;" @click="$emit('click', $event)">
      <v-list-item-avatar class="ma-0" size="30">
        <v-img v-if="userProfilePic" :src="userProfilePic" />
        <v-icon v-else>mdi-account</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="ms-1">
          <span v-if="hasProfile">
            {{ firstname }} {{ lastname }}
          </span>
          <span v-else>{{ $t('components.admin.appbar.fillProfile') }}</span>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="mx-1">
        <v-icon>mdi-chevron-down</v-icon>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
  import UserProfile from '@peynman/press-vue-core/mixins/UserProfile'

  export default {
    name: 'UserAccountBarIcon',
    mixins: [UserProfile],
    props: {
      color: String,
      light: Boolean,
      dark: Boolean,
    },
  }
</script>

<template>
  <div class="d-flex flex-column">
    <v-card-title :class="headerClass">{{ $t('components.website.signin.title') }}</v-card-title>
    <v-divider />
    <v-card-text :class="bodyClass">
      <validations-alert :type="formAlertType" :errors="formAlertErrorList" :message="formAlertMessage" />
      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="username"
          :disabled="otcSent || loading"
          :label="$t('components.website.signin.username')"
          :hint="$t('components.website.signin.usernameHint')"
          :rules="[getRequiredRule(), getUsernameRule()]"
          v-bind="theme.website.input"
          @keyup="onKeyUp"
        />
        <v-text-field
          v-if="modePassword || otcSent"
          v-model="password"
          :label="$t('components.website.signin.password')"
          type="password"
          :rules="passwordRules"
          :disabled="loading"
          v-bind="theme.website.input"
          @keyup="onKeyUp"
        />
        <captcha-input
          v-if="!otcSent"
          v-model="captcha"
          :rules="[getRequiredRule()]"
          :disabled="loading"
          @submit="onSubmit"
        />
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions :class="`d-flex flex-wrap flex-row align-center justify-center ${actionsClass}`">
      <v-btn
        class="ms-3"
        color="green"
        dark
        :loading="loading"
        @click="onSubmit"
      >{{ otcSent || modePassword ? $t('components.website.signin.submit') : $t('components.website.signin.sendOTC') }}</v-btn>
      <v-spacer />
      <div class="d-flex flex-column align-center justify-center me-3">
        <v-switch
          v-model="modePassword"
          :label="$t('components.website.signin.withPassword')"
          :disabled="otcSent || loading"
          inset
          dense
        />
      </div>
    </v-card-actions>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CaptchaInput from '@peynman/press-vue-admin/components/Inputs/CaptchaInput/CaptchaInput.vue'
  import ValidationsAlert from '@peynman/press-vue-admin/components/Inputs/ValidationsAlert/ValidationsAlert.vue'
  import FormValidations from '@peynman/press-vue-core/mixins/FormValidations'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import { toEnglishDigits } from '@peynman/press-vue-core/utils/helpers'

  export default {
    name: 'SigninForm',
    components: {
      CaptchaInput,
      ValidationsAlert,
    },
    mixins: [
      Themeable,
      FormValidations([
        'username',
        'password',
        'captcha',
      ])],
    props: {
      value: Object,
      bodyClass: String,
      headerClass: String,
      actionsClass: String,
    },
    data: () => ({
      valid: false,
      loading: false,
      otcSent: false,
      modePassword: false,
    }),
    computed: {
      canSubmit () {
        return !this.captchaLoading && this.valid
      },
      ...mapGetters('captcha', [
        'captchaLoading',
        'captchaKey',
      ]),
      passwordRules () {
        return this.modePassword
          ? [
            this.getRequiredRule(),
            this.getMinLengthRule(6),
          ]
          : []
      },
    },

    methods: {
      onKeyUp (e) {
        if (e.keyCode === 13) {
          this.onSubmit()
        }
      },

      onSubmit () {
        this.$refs.form.validate()

        if (this.valid) {
          this.loading = true
          if (this.modePassword) {
            this.signinWithPassword()
          } else {
            if (this.otcSent) {
              this.signinWithOTC()
            } else {
              this.sendOTC()
            }
          }
        }
      },

      sendOTC () {
        this.$store.dispatch('auth/sendOTC', {
          phone: toEnglishDigits(this.username),
          captcha: toEnglishDigits(this.captcha),
          key: this.captchaKey,
        })
          .then(json => {
            this.updateFormSuccessMessage(json.message)
            this.otcSent = true
          })
          .catch(error => {
            this.updateFormValidationErrors(error)
            this.otcSent = false
          })
          .finally(() => {
            this.loading = false
          })
      },

      signinWithOTC () {
        this.$store.dispatch('auth/verifyOTC', {
          phone: toEnglishDigits(this.username),
          code: toEnglishDigits(this.password),
        })
          .then(json => {
            this.updateFormSuccessMessage(json.message)
            setTimeout(() => { this.$emit('success') }, this.theme.website.signinRedirectDelay)
          })
          .catch(error => {
            this.updateFormValidationErrors(error)
            this.otcSent = false
          })
          .finally(() => {
            this.loading = false
          })
      },

      signinWithPassword () {
        this.$store.dispatch('auth/signin', {
          username: toEnglishDigits(this.username),
          password: toEnglishDigits(this.password),
          captcha: toEnglishDigits(this.captcha),
          key: this.captchaKey,
        })
          .then(json => {
            this.updateFormSuccessMessage(json.message)
            setTimeout(() => { this.$emit('success') }, this.theme.website.signinRedirectDelay)
          })
          .catch(error => {
            this.updateFormValidationErrors(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>
